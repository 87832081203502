@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat:wght@500&display=swap');
html {
    scroll-behavior: smooth;
}
.lato {
    font-family: 'Lato', sans-serif;
}
.monte {
    font-family: 'Montserrat', sans-serif;
}
.background {
    z-index: -1;
}
#backgrond_top {
    position: absolute;
    z-index: -1;
}
#backgrond_bot {
    position: absolute;
    z-index: -2;
}
